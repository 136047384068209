import { Button } from '@remarkable/ark-web';
import { List } from 'phosphor-react';
import { FC } from 'react';

type Props = {
  onClick: () => void;
  tabIndex?: number;
};

export const HamburgerButton: FC<Props> = ({ onClick, tabIndex }) => {
  return (
    <Button
      aria-label="main-menu-button"
      tabIndex={tabIndex}
      variant="tertiary-neutral"
      onClick={onClick}
      data-cy="menu-hamburger-button"
    >
      <List />
    </Button>
  );
};
