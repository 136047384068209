import { Button } from '@remarkable/ark-web';
import { setTrackClick } from '@remarkable/tracking-browser/lib/ampli';
import { BuyNowClicked, Navigate } from 'ampli-types';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import NextLink from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { getNumberOfItemsInCart } from 'src/helpers/cartHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import { tracker } from 'src/services/tracker';
import { ButtonTypes, ComponentLocations } from 'src/services/tracking/eventTypes';
import { isMobile } from 'src/utils/isMobile';
import { OnCtaClicked } from 'src/views/MainPage';

import CartButton from './CartButton';
import { HamburgerButton } from './Hamburgerbutton';
import { LeftSubMenu } from './LeftSubMenu';
import { Logo } from './Logo';

export type HighlightedMenuPages =
  | 'connect'
  | 'store'
  | 'business'
  | 'blog'
  | 'roomToThink'
  | 'about'
  | 'using-remarkable'
  | 'sustainability';

export type Props = {
  showCart?: boolean; //Needs this to load cart before showing
  showEmptyCart?: boolean;
  isStatic?: boolean;
  hideOnScroll?: boolean;
  style?: string;
  isDarkOnTop?: boolean;
  backgroundColor?: string;
  activeNotification?: boolean;
  highLightedMenuPage?: HighlightedMenuPages;
  alternateStyle?: boolean; // inverts color of buy butten
} & { onClickRightElement?: OnCtaClicked; buyButtonTrackClickData?: { [key: string]: string } };

const invertCtaBtnColorSchemeWhenBelow = (trigger?: HTMLElement | null) => {
  if (trigger) {
    const triggerWhenBelowElement = trigger;
    const offsetTop = triggerWhenBelowElement?.getBoundingClientRect().top;
    const heightOfMenu = 43;
    if (offsetTop && offsetTop <= -heightOfMenu) {
      return true;
    }
  }
  return false;
};

type InvertTrigger = boolean | HTMLElement | null | undefined;
type InvertTriggerWhen = 'below';
/**
 * invertColor
 * @param trigger : passed to custom function to check if change should happen
 * @param type: optional : selects which custom function to use for check
 * @returns boolean
 */
const invertColor = (trigger: InvertTrigger, when?: InvertTriggerWhen) => {
  const isHtmlElement = (trigger: InvertTrigger): trigger is HTMLElement => {
    return trigger instanceof HTMLElement;
  };
  if (!trigger) {
    return false;
  } else if (isHtmlElement(trigger) && when == 'below') {
    return invertCtaBtnColorSchemeWhenBelow(trigger);
  }
};

export const Menu: React.FC<Props> = ({
  showCart = false,
  showEmptyCart = false,
  hideOnScroll,
  style,
  isDarkOnTop,
  backgroundColor = 'bg-light',
  children,
  isStatic = false,
  activeNotification = false,
  highLightedMenuPage,
  onClickRightElement,
  buyButtonTrackClickData,
  alternateStyle = false,
}) => {
  const [state, setState] = useState({ shouldHide: false, scrollPosition: 0 });
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const currentBp: BP | null = useActiveBreakpoint();
  const changeColorWhenBelowElement = useRef<HTMLElement | null>(null);

  const handleScroll = debounce(() => {
    const { pageYOffset } = window;
    const scrollHide = activeNotification ? pageYOffset > 35 : pageYOffset > 0;
    setState((prevState) => ({
      scrollPosition: pageYOffset,
      shouldHide: subMenuOpen || (prevState.scrollPosition < pageYOffset && scrollHide),
    }));
  }, 9);

  useEffect(() => {
    const heroCtaBtn = document.getElementById('hero-cta-buy-btn');
    if (heroCtaBtn) {
      changeColorWhenBelowElement.current = heroCtaBtn;
    }
  }, []);

  useEffect(() => {
    if (hideOnScroll) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [subMenuOpen]);

  const handleCloseSubMenu = () => {
    setSubMenuOpen(false);
    const { pageYOffset } = window;
    setState(() => ({
      scrollPosition: pageYOffset,
      shouldHide: false,
    }));
  };

  const handleOpenSubMenu = () => {
    pushGAEventToDataLayer(eventCategoryEnum.MAIN_NAVIGATION, eventActionEnum.NAVIGATION, 'Open hamburger menu');
    setSubMenuOpen(true);
    const { pageYOffset } = window;
    tracker.trackEvent(
      new Navigate({
        component_location: ComponentLocations.MENU.HEADER_HAMBURGER_MENU,
        action: 'open',
        type: 'menu',
      })
    );
    setState(() => ({
      scrollPosition: pageYOffset,
      shouldHide: !isStatic,
    }));
  };

  const logoClicked = () => {
    pushGAEventToDataLayer(eventCategoryEnum.MAIN_NAVIGATION, eventActionEnum.NAVIGATION, 'reMarkable logo');
    tracker.trackEvent(
      new Navigate({
        component_location: ComponentLocations.STORE_PAGES.HEADER,
        action: 'click',
        type: 'logo',
      })
    );
  };

  const buyButtonClicked = (onClickRightElement?: OnCtaClicked) => {
    if (!onClickRightElement) {
      // Default behavior for "buy button" in Menu
      pushGAEventToDataLayer(eventCategoryEnum.MAIN_NAVIGATION, eventActionEnum.CTA, 'Buy now');
    } else {
      onClickRightElement();
    }
  };

  const buyButtonTrackClick = {
    component_location: ComponentLocations.STORE_PAGES.HEADER,
    link_source: '/store/remarkable-2',
    text: 'Buy now',
    button_type: ButtonTypes.PURCHASE.BUY_NOW,
    ...buyButtonTrackClickData,
  };

  useEffect(() => {
    if (subMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [subMenuOpen]);

  const [shouldShowCart, setShouldShowCart] = useState(!!showCart);
  const cart = useReduxCart();

  useEffect(() => {
    const numberOfItemsInCart = getNumberOfItemsInCart(cart.data);

    if ((showCart && numberOfItemsInCart > 0) || showEmptyCart) {
      setShouldShowCart(true);
    } else if (numberOfItemsInCart < 1) {
      setShouldShowCart(false);
    }
  }, [cart.data]);

  //scroll length was 25 && 55 - but increased to avoid "flickering" white if scrolling just a little down and up on mp
  const scrollChange = 1025;
  const scrollChangeWithPromo = 1055;

  const changeBasedOnScrollPosition =
    state.scrollPosition < scrollChange || (state.scrollPosition < scrollChangeWithPromo && activeNotification);
  const isDarkNav = !!isDarkOnTop && changeBasedOnScrollPosition;

  return (
    <header>
      {children}
      <nav
        data-theme={isDarkNav ? 'on-dark' : 'on-light'}
        className={cx(
          'items-center px-16 py-8 w-full z-30',
          { fixed: !isStatic && !(state.scrollPosition < 25 && activeNotification) },
          style,
          state.shouldHide || changeBasedOnScrollPosition ? backgroundColor : 'bg-light',
          state.shouldHide
            ? 'top-[-1px] p-0 translate-y-[-100%]'
            : state.scrollPosition < 25 && activeNotification
            ? 'translate-y-0'
            : 'translate-y-0 top-0',
          'transition ease-in-out duration-300'
        )}
      >
        <ul className="flex flex-row items-center max-w-[1360px] w-full m-auto p-0 [&>li]:list-none [&>li]:flex-1">
          <li className={cx('flex justify-start', { invisible: subMenuOpen })}>
            <HamburgerButton
              tabIndex={!subMenuOpen ? 0 : -1}
              onClick={() => {
                handleOpenSubMenu();
              }}
            />
          </li>
          <li className="flex justify-center">
            <Logo onClick={logoClicked} />
          </li>
          <li className="flex justify-end">
            {shouldShowCart ? (
              <CartButton />
            ) : (
              <NextLink href="/store/remarkable-2" passHref legacyBehavior>
                <Button
                  as="a"
                  variant={
                    alternateStyle && !invertColor(changeColorWhenBelowElement.current, 'below')
                      ? 'secondary'
                      : 'primary'
                  }
                  size="small"
                  data-cy="buy-now-btn"
                  className="z-30"
                  onClick={() => buyButtonClicked(onClickRightElement)}
                  {...setTrackClick(new BuyNowClicked(buyButtonTrackClick))}
                >
                  {`Buy${currentBp && isMobile(currentBp) ? '' : ' now'}`}
                </Button>
              </NextLink>
            )}
          </li>
        </ul>
      </nav>
      <LeftSubMenu
        menuOpen={subMenuOpen}
        handleClose={() => handleCloseSubMenu()}
        highLightedMenuPage={highLightedMenuPage}
      />
    </header>
  );
};
