import { isProduction } from './isProduction';

interface AppendTypes {
  title: string;
  append: string;
}

export const appendToPageTitle = ({ title, append }: AppendTypes) => {
  const debug = false;
  if (title && typeof title === 'string' && append && typeof append === 'string') {
    const appendFull = ` | ${append}`;
    // If 'title' already has ' | reMarkable' appended to it, return it as is
    if (title.includes(appendFull)) {
      return title;
    }
    // If 'title' prop received by the page is just 'reMarkable', do not append ' | reMarkable' to page title.
    // That's the only exception.
    if (title === append) {
      return title;
    }
    // If we're still here, append ' | reMarkable' to 'title'
    return `${title}${appendFull}`;
  } else if (
    // * We're hardly going to find us in this situation, but when we do we'll be ready
    // If 'title' is falsy
    (!title ||
      // ...or if 'title' is defined but not a string
      (title && typeof title !== 'string')) &&
    // ...while 'append' is valid, return 'append'
    append &&
    typeof append === 'string'
  ) {
    // ...return 'append'
    return append;
  } else {
    // If we aren't at 'production' and 'debug' is set to true, return a console warning.
    if (!isProduction && debug) {
      console.warn(
        `[appendToPageTitle] 'title' and 'append' are either both empty strings or not strings at all: {title: ${title}, append: ${append}}.`
      );
    }
  }
  // Return what was passed as 'title', even if 'title' is not a valid string.
  return title;
};
// See tests
