import { Link as ArkLink } from '@remarkable/ark-web';
import NextLink from 'next/link';
import { FC } from 'react';

type Props = {
  onClick: () => void;
};

export const Logo: FC<Props> = ({ onClick }) => {
  return (
    <NextLink href="/" passHref legacyBehavior>
      <ArkLink onClick={onClick}>
        <p className={`px-[6px] py-[1px] font-serif text-24`}>reMarkable</p>
      </ArkLink>
    </NextLink>
  );
};
