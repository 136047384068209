import { Button } from '@remarkable/ark-web';
import { CartInteraction } from 'ampli-types';
import { HandbagSimple } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { getNumberOfItemsInCart } from 'src/helpers/cartHelpers';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { setCartVisible } from 'src/redux/actions/storeActions2';
import { tracker } from 'src/services/tracker';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { getTrackingProducts, getTrackingProductsTotal } from 'src/services/tracking/utils';

const CartButton = () => {
  const dispatch = useDispatch();
  const [cartSize, setCartSize] = useState(0);
  const cart = useReduxCart();
  const gtmProductsData = useGTMProductsData();
  const trackingProducts = getTrackingProducts(cart.data, gtmProductsData);

  useEffect(() => {
    //TODO: Should be changed once connect is removed from being a cart item
    const numberOfItemsInCart = getNumberOfItemsInCart(cart.data) - cart.helpers.getAmountOfConnectInCart();
    setCartSize(numberOfItemsInCart);
  }, [cart]);

  return (
    <Button
      variant="tertiary-neutral"
      className={`group relative font-medium
      before:pt-[4px] before:top-[8px] before:right-[4px] before:content-[attr(data-cart-size)] 
      before:absolute before:h-[20px] before:w-[20px] before:bg-brand-orange-400 
      before:rounded-full before:text-12 before:text-light ${cartSize < 1 && 'before:hidden'}`}
      id="show-cart"
      aria-label="Show cart"
      onClick={() => {
        dispatch(setCartVisible(true));
        tracker.trackEvent(
          new CartInteraction({
            component_location: ComponentLocations.STORE_PAGES.HEADER,
            action: 'open',
            cart_products: trackingProducts,
            cart_value: getTrackingProductsTotal(trackingProducts),
          })
        );
      }}
      {...(cartSize > 0 && { 'data-cart-size': cartSize })}
    >
      <HandbagSimple className="align-bottom" />
    </Button>
  );
};

export default CartButton;
