import { useSelector } from 'react-redux';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { State } from 'src/redux/reducers';

type Props = {
  openCountrySelector: () => void;
  countrySelectorIsOpen: boolean;
};

const CountrySelector: React.FC<Props> = ({ openCountrySelector, countrySelectorIsOpen }) => {
  const country = useSelector((state: State) => state.countryDetector.country);
  const countryData = useSelector((state: State) => state.staticQuery.countryData);

  const currentCountry = getCountryInfoByValue(countryData, country);

  return (
    <button
      data-cy="country-selector-button"
      className={`flex items-center text-16 text-dark border-solid border border-grayscale-gray-300 rounded-full p-8 hover:bg-[#E9E9E9] focus-visible:bg-brand-orange-200 ${
        countrySelectorIsOpen ? 'bg-[#E9e9e9]' : ''
      }`}
      type="button"
      onClick={() => {
        openCountrySelector();
      }}
    >
      <img
        src={currentCountry.flag?.asset?.url}
        className="mr-4"
        height="27"
        width="27"
        alt=""
        loading="lazy"
        data-cy={`country-selected-${currentCountry.label}`}
      />
      <p className="mr-4">Country/region</p>
    </button>
  );
};

export default CountrySelector;
