import { isProduction } from './isProduction';

interface TruncateTypes {
  txt: string;
  length: number;
  soft?: boolean;
}

export const truncate = ({ txt, length, soft }: TruncateTypes) => {
  const debug = false;
  if (txt && typeof txt === 'string') {
    // 'soft' mode truncates with a '...' at the end.
    // The '...' will only appear if we meet the right conditions.
    if (soft) {
      const truncateLength = length - 3;
      const txtOutput = txt.substring(0, truncateLength);
      return `${txtOutput}${txt.length >= length - 2 ? '...' : ''}`;
    } else {
      const truncateLength = length;
      const txtOutput = txt.substring(0, truncateLength);
      return txtOutput;
    }
  } else {
    // If we aren't at 'production' and 'debug' is set to true, return a console warning.
    if (!isProduction && debug) {
      console.warn(`[truncate] 'txt' is either an empty string or not a string at all: [${txt}].`);
    }
  }
  // Return what was passed as 'txt', even if 'txt' is not a valid string.
  return txt;
};
// See tests
