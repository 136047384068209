/**
 * Head component that contains shared meta-tags and other <head/> contents.
 * Also contains <script/> tags.
 */

import Head from 'next/head';
import { FC } from 'react';

const RmHead: FC = () => {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="facebook-domain-verification" content="fh59fjjoml9wwsyqac1y8pdmkqc6xp" />
      <link rel="preconnect" href="https://cdn.sanity.io/" />
      {/* Preload fonts*/}
      {/* MaisonNeue-Book.woff2 */}
      <link
        rel="preload"
        href="https://cdn.sanity.io/files/0e4kwcjv/production/75d4b7285a86034d42407a9a9e4ada492b1f06c1.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      {/* MaisonNeue-Medium.woff2 */}
      <link
        rel="preload"
        href="https://cdn.sanity.io/files/0e4kwcjv/production/bf6ab79a7125f5ddb3607a5bf71a3840b1a4e1ad.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      {/* MaisonNeue-Demi.woff2 */}
      <link
        rel="preload"
        href="https://cdn.sanity.io/files/0e4kwcjv/production/aef586705de1a889d869875b5a218ee35e2d4ada.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      {/* MaisonNeue-Bold.woff2 */}
      <link
        rel="preload"
        href="https://cdn.sanity.io/files/0e4kwcjv/production/b266528efd6beb62b8d8fdd282b0cd82199612dd.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      {/* reMarkablePortrait-Medium-Web.woff2 */}

      <link
        rel="preload"
        href="https://cdn.sanity.io/files/0e4kwcjv/production/6b264bca38335392afd91bf8553d5fa18f48d9c6.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      {/* reMarkablePortrait-MediumItalic-Web.woff2 */}
      <link
        rel="preload"
        href="https://cdn.sanity.io/files/0e4kwcjv/production/469c94e1e37ee8624445c71192b5bafbc23c0209.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link rel="icon" href="/favicon-32x32.png" type="image/png" />
      <link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/icons/icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="192x192" href="/icons/icon-192x192.png" />
      <link rel="apple-touch-icon" sizes="256x256" href="/icons/icon-256x256.png" />
      <link rel="apple-touch-icon" sizes="384x384" href="/icons/icon-384x384.png" />
      <link rel="apple-touch-icon" sizes="512x512" href="/icons/icon-512x512.png" />
      <link rel="manifest" href="/manifest.json" />
    </Head>
  );
};

export default RmHead;
