import Head from 'next/head';
import { useRouter } from 'next/router';
import { OgImage, Seo } from 'src/typings/runtime-typecheck/seo';
import { getAbsoluteUrl } from 'src/utils';
import { appendToPageTitle } from 'src/utils/appendToPageTitle';
import { truncate } from '../utils/truncate';

interface SeoTypes {
  title?: string;
  description?: string;
  ogImage?: OgImage;
  seo?: Seo;
}

const seoFallback = {
  title: 'reMarkable',
  description: 'Replace your notebooks and printed documents with the only tablet that feels like paper.',
  ogImage: {
    url: 'https://cdn.sanity.io/images/0e4kwcjv/production/fa8cf2f316203111c843c229347f6bee7528f94e-1200x630.jpg',
    type: 'image/jpg',
    width: '1200',
    height: '630',
    alt: 'reMarkable 2 - The next generation paper tablet',
  },
};

const SeoHead = ({ title, description, ogImage, seo }: SeoTypes): JSX.Element => {
  const router = useRouter();
  // * 'robots', defaults to indexing
  const robotsIndex = seo?.noIndex ? 'noindex' : 'index';
  const robotsFollow = seo?.noFollow ? 'nofollow' : 'follow';
  // * 'title' is applied on this order: seo.title > title > fallback title
  const titleTruncated = truncate({ txt: seo?.title || title || seoFallback.title, length: 80, soft: true });
  const titleTruncatedTwitter = truncate({ txt: seo?.title || title || seoFallback.title, length: 70, soft: true });
  // * 'description' is applied on this order: seo.description > description > fallback description
  const descriptionTruncated = truncate({
    txt: seo?.description || description || seoFallback.description,
    length: 160,
    soft: true,
  });
  const descriptionTruncatedTwitter = truncate({
    txt: seo?.description || description || seoFallback.description,
    length: 200,
    soft: true,
  });
  // * 'ogImage' is applied on this order: seo ogImage > ogImage > fallback ogImage
  const ogImageUrl = seo?.ogImage?.url || ogImage?.url || seoFallback.ogImage.url;
  const ogImageType = seo?.ogImage?.type || ogImage?.type || seoFallback.ogImage.type;
  const ogImageWidth = seo?.ogImage?.width || ogImage?.width || seoFallback.ogImage.width;
  const ogImageHeight = seo?.ogImage?.height || ogImage?.height || seoFallback.ogImage.height;
  const ogImageAlt = seo?.ogImage?.alt || seoFallback.ogImage.alt;
  // * 'pageTitle' comprises of the resolved 'title' and that which is appended to every page
  const pageTitle = appendToPageTitle({ title: seo?.title || title || seoFallback.title, append: seoFallback.title });
  const pageUrl = getAbsoluteUrl(router);
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      {/* Robots / Search Engines */}
      <meta name="robots" content={`${robotsIndex}, ${robotsFollow}`} />
      {/* Open Graph, see https://ogp.me/ */}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleTruncated} />
      <meta property="og:description" content={descriptionTruncated} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:type" content={ogImageType} />
      <meta property="og:image:width" content={`${ogImageWidth}`} />
      <meta property="og:image:height" content={`${ogImageHeight}`} />
      <meta property="og:image:alt" content={ogImageAlt} />
      {/* Twitter, see https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@remarkablepaper" />
      <meta name="twitter:description" content={descriptionTruncatedTwitter} />
      <meta name="twitter:image" content={ogImageUrl} />
      <meta name="twitter:site" content="@remarkablepaper" />
      <meta name="twitter:title" content={titleTruncatedTwitter} />
      <meta property="twitter:domain" content="remarkable.com" />
      <meta property="twitter:url" content={pageUrl} />
    </Head>
  );
};
  
export default SeoHead;

// * Twitter Tag Guide
// twitter:card // summary | summary_large_image | app | player
// twitter:site // @username of website
// twitter:creator // @username of content creator
// twitter:description // Description of content (maximum 200 characters)
// twitter:title // Title of content (max 70 characters)
// twitter:image // URL of image to use in the card. Images must be less than 5MB in size. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
// twitter:image:alt // A text description of the image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters.
