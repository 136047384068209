import { Button, Link, SearchInput } from '@remarkable/ark-web';
import classNames from 'classnames';
import { CaretLeft, X } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import * as countryDetectorActions from 'src/redux/actions/countryDetectorActions';
import { State } from 'src/redux/reducers';

type Props = {
  handleCloseMenu: () => void;
  handleCloseCountryMenu: () => void;
  isOpen: boolean;
};

const CountryMenu: React.FC<Props> = ({ handleCloseMenu, handleCloseCountryMenu, isOpen }) => {
  const country = useSelector((state: State) => state.countryDetector.country);
  const countryData = useSelector((state: State) => state.staticQuery.countryData);
  const currentCountry = getCountryInfoByValue(countryData, country);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState('');

  const searchCountries: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => setFilter(value);
  const clearSearch = () => setFilter('');
  const changeCountrySelected = (label: string) => {
    dispatch(countryDetectorActions.changeCountry(label));
    handleCloseCountryMenu();
  };

  useEffect(() => {
    if (isOpen) clearSearch();
  }, [isOpen]);

  const countries = useMemo(() => {
    if (!filter) return countryData;
    return countryData.filter(
      (item) =>
        item.label.toLowerCase().includes(filter.toLowerCase()) ||
        item.continent.toLowerCase().includes(filter.toLowerCase())
    );
  }, [filter, countryData]);

  return (
    <div
      className="bg-[#e9e9e9] w-full max-h-full py-40 h-full px-32 sm:px-[72px]"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="lg:hidden w-full flex justify-between pb-40">
        <Link
          tabIndex={isOpen ? 0 : -1}
          className="px-0"
          as="button"
          aria-label="back-to-menu-button"
          onClick={() => {
            handleCloseCountryMenu();
          }}
        >
          <CaretLeft size={24} />
          <p className="font-demi">Back to menu</p>
        </Link>
        <Button
          tabIndex={isOpen ? 0 : -1}
          aria-label="close-country-menu"
          data-cy="close-countries-menu"
          variant="tertiary-neutral"
          onClick={() => {
            clearSearch();
            handleCloseMenu();
          }}
        >
          <X size={32} />
        </Button>
      </div>
      <SearchInput
        tabIndex={isOpen ? 0 : -1}
        className="[&>input]:border-0 w-full [&>input]:w-full mb-20" // Not running preflight
        placeholder={'Search country/region'}
        name={'Search country/region'}
        onChange={(e) => searchCountries(e)}
        value={filter}
      />
      <ul
        className="overflow-y-auto max-h-full z-10 mb-[200px] pb-[200px] lg:pb-[120px] list-none -mr-32 sm:mr-[-72px] pr-32 sm:pr-[72px]"
        style={{ marginBlockStart: 0, paddingInlineStart: 0 }}
        data-cy="country-list"
        tabIndex={-1}
      >
        {countries.map((country) => {
          return (
            <li key={`country-${country.label}`}>
              <Link
                tabIndex={isOpen ? 0 : -1}
                aria-label={`country-${country.label}`}
                data-cy="country-selector"
                as="button"
                type="button"
                className={classNames(
                  'transition-none w-full p-8 no-underline hover:no-underline hover:font-demi flex gap-16 focus-visible:rounded-full',
                  {
                    'bg-grayscale-gray-100 rounded-full font-demi hover:bg-grayscale-gray-100':
                      currentCountry.label === country.label,
                  }
                )}
                onClick={() => changeCountrySelected(country.value)}
              >
                <img src={country.flag?.asset?.url} height="27" width="27" alt="" loading="lazy" />
                <span data-cy={`country-${country.label}`}>{country.label}</span>
              </Link>
            </li>
          );
        })}
      </ul>

      <div className="w-full sm:w-[480px] h-[136px] mt-[-90px] bg-gradient-to-t from-[#e9e9e9] to-transparent z-40 absolute bottom-0 left-0 lg:bottom-unset pointer-events-none"></div>
    </div>
  );
};

export default CountryMenu;
