import { Button, Link as ArkLink } from '@remarkable/ark-web';
import NextLink from 'next/link';
import { FacebookLogo, InstagramLogo, TwitterLogo, UserCircle, X } from 'phosphor-react';
import { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';

import { ButtonClicked } from 'ampli-types';
import { tracker } from 'src/services/tracker';
import { ButtonTypes, ComponentLocations } from 'src/services/tracking/eventTypes';
import { HighlightedMenuPages } from '.';
import CountryMenu from './CountryMenu';
import CountrySelector from './CountrySelector';

type Props = {
  handleClose: () => void;
  menuOpen: boolean;
  highLightedMenuPage?: HighlightedMenuPages;
};

const getMenuItem = (
  { title, link, targetBlank = false, name, badge }: MenuItem,
  highLightedMenuPage?: HighlightedMenuPages
): JSX.Element => {
  const onClick = () => {
    pushGAEventToDataLayer(eventCategoryEnum.MAIN_NAVIGATION, eventActionEnum.MAIN_NAVIGATION, title);
  };

  return name ? (
    <NextLink href={link} passHref legacyBehavior>
      <ArkLink key={'menu-' + title} data-cy={`menu-${name}-button`} onClick={() => onClick()}>
        <p className={`${name == highLightedMenuPage ? '!underline !decoration-brand-orange-400' : ''}`}>{title}</p>
        {badge && (
          <span className="bg-grayscale-gray-500 text-[10px] text-white uppercase px-8 py-4 rounded-full decoration-grayscale-gray-500 decoration-solid underline tracking-widest	font-demi">
            {badge}
          </span>
        )}
      </ArkLink>
    </NextLink>
  ) : (
    <ArkLink target={`${!!targetBlank ? '_blank' : '_self'}`} href={link} key={'menu-' + title}>
      {title}
    </ArkLink>
  );
};

type MenuItem = {
  title: string;
  link: string;
  targetBlank?: boolean;
  name?: HighlightedMenuPages;
  badge?: string;
};

// Every item with a name (used for testing) is an internal page - and will have tracking.
const largeMenuItems: MenuItem[] = [
  { title: 'Store', link: '/store', name: 'store' },
  { title: 'Support and FAQs', link: 'https://support.remarkable.com/', targetBlank: true },
  { title: 'For business', link: '/business', name: 'business' },
];

const smallMenuItems: MenuItem[] = [
  { title: 'Connect', link: '/store/connect', name: 'connect' },
  { title: 'Sustainability', link: '/sustainability', name: 'sustainability', badge: 'new' },
  { title: 'Using reMarkable', link: '/using-remarkable', name: 'using-remarkable' },
  { title: 'Room to Think', link: '/roomtothink', name: 'roomToThink' },
  { title: 'Blog', link: '/blog', name: 'blog' },
  { title: 'About', link: '/about', name: 'about' },
];

export const LeftSubMenu: React.FC<Props> = ({ handleClose, menuOpen, highLightedMenuPage }) => {
  const [showCountryMenu, setShowCountryMenu] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(menuOpen);

  useEffect(() => {
    //if closing menu; delay a little bit so that transition can complete first. Dont want the buttons rendered for tab-navigation when hidden.
    if (!menuOpen) {
      setTimeout(() => {
        setSideMenuOpen(false);
      }, 1000);
    } else {
      setSideMenuOpen(true);
    }
  }, [menuOpen]);

  const closeCountryMenu = () => {
    setShowCountryMenu(false);
  };

  const openCountrySelector = () => {
    setShowCountryMenu(!showCountryMenu);
  };

  const closeMenu = () => {
    closeCountryMenu();
    handleClose();
  };

  return (
    <FocusLock disabled={!menuOpen} autoFocus={false}>
      <div
        className={`fixed z-30 w-full h-full flex ${menuOpen ? 'left-0 top-0' : 'left-[-100%] top-0 delay-[1000ms]'} `}
      >
        <div
          className={`relative top-0 z-30 w-full h-full flex ${
            menuOpen ? ' bg-black/50' : ' bg-transparent '
          } duration-[1000ms]`}
          onClick={() => closeMenu()}
        >
          <nav
            className={`bg-light bg-fixed w-full sm:w-[480px] h-full py-40 flex-col justify-between z-30 flex duration-500  absolute ${
              menuOpen ? 'left-0 overflow-auto' : 'left-[-100%] lg:delay-[300ms] '
            }`}
            onClick={(event) => event.stopPropagation()}
            data-cy="menu-main-links-pane"
          >
            {(sideMenuOpen || menuOpen) && (
              <>
                <div className="pl-32 xs:pl-56 sm:pl-[72px] [&>ul]:list-none [&>ul]:pl-0">
                  <div className="flex flex-row items-center justify-between pr-24 xs:pr-40">
                    <ArkLink
                      href="https://my.remarkable.com/"
                      target="_blank"
                      className="text-18"
                      onClick={() => {
                        tracker.identify({
                          myrm_utm_parameters: JSON.stringify({
                            myrm_utm_source: 'rm.com menu login',
                          }),
                        });
                        tracker.trackEvent(
                          new ButtonClicked({
                            button_type: ButtonTypes.NAVIGATION.EXTERNAL_LINK,
                            component_location: ComponentLocations.MENU.HEADER_HAMBURGER_MENU,
                            text: 'Log in',
                          })
                        );
                      }}
                    >
                      <UserCircle className="text-dark" />
                      <span>Log in</span>
                    </ArkLink>
                    <Button data-cy="close-menu" onClick={() => closeMenu()} variant="tertiary-neutral">
                      <X size={32} />
                    </Button>
                  </div>

                  <ul className="my-40 md:my-[80px] text-24 font-demi">
                    {largeMenuItems.map((item) => {
                      return (
                        <li key={item.link} className="my-24">
                          {getMenuItem(item, highLightedMenuPage)}
                        </li>
                      );
                    })}
                  </ul>

                  <ul className="font-medium">
                    {smallMenuItems.map((item) => {
                      return (
                        <li key={item.link} className="my-24 md:my-32">
                          {getMenuItem(item, highLightedMenuPage)}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="flex flex-col-reverse sm:flex-row pb-32 pl-32 xs:pl-56 sm:pl-[72px] mt-20">
                  <div className="w-full mb-24 -mr-[10px]">
                    <CountrySelector
                      openCountrySelector={openCountrySelector}
                      countrySelectorIsOpen={showCountryMenu}
                    />
                  </div>
                  <ul
                    className="flex items-center w-full mb-24 ml-0 list-none"
                    style={{ marginBlockStart: 0, paddingInlineStart: 0 }}
                  >
                    <li>
                      <ArkLink className="mr-20" href="https://nb-no.facebook.com/remarkableAS/" target="_blank">
                        <FacebookLogo />
                      </ArkLink>
                    </li>
                    <li>
                      <ArkLink className="mr-20" href="https://www.instagram.com/remarkable/" target="_blank">
                        <InstagramLogo />
                      </ArkLink>
                    </li>
                    <li>
                      <ArkLink className="mr-20" href="https://twitter.com/remarkablepaper" target="_blank">
                        <TwitterLogo />
                      </ArkLink>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </nav>

          <div
            className={`${
              showCountryMenu
                ? 'left-0 lg:left-[480px] duration-500'
                : 'left-[-100%] duration-[900ms] lg:duration-[2000ms]'
            } w-full sm:w-[480px] relative z-30 lg:z-20 bottom-0`}
            data-cy="menu-country-picker-pane"
          >
            <CountryMenu
              handleCloseCountryMenu={closeCountryMenu}
              handleCloseMenu={closeMenu}
              isOpen={showCountryMenu}
            />
          </div>
        </div>
      </div>
    </FocusLock>
  );
};
